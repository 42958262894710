import {defineStore} from "pinia";
import Item from "~/dto/CarQueueItem.js";
import type TrackingSessionResponse from "~/api/response/Cars/Queue/TrackingSessionResponse";
import {FetchError} from "ofetch";
import TrackingSession from "~/dto/Cars/Queue/TrackingSession";

export const useTrackingStore = defineStore('tracking', () => {
    const REGISTRATION_NUMBER_STORAGE_KEY = 'tracking_registration_number';
    const TRACKING_SESSION_ID_STORAGE_KEY = 'tracking_session_id';

    const loading = ref<boolean>(false);
    const trackingSessionId = ref<string | null>(localStorage?.getItem(TRACKING_SESSION_ID_STORAGE_KEY));
    const registrationNumber = ref<string | null>(localStorage?.getItem(REGISTRATION_NUMBER_STORAGE_KEY));

    const trackingSession = ref<TrackingSession | null>(null);
    let trackSessionInterval: NodeJS.Timeout | undefined;

    const queueItem = computed<Item | null>(() => trackingSession.value?.item || null);

    const hasTrackingSession = () => !!trackingSessionId.value;

    const startRefreshing = () => {
        trackSessionInterval = setInterval(refreshQueueItem, 10000);
    };
    const stopRefreshing = () => {
        clearInterval(trackSessionInterval);
    };

    const handleTrackingSessionResponse = async (trackingSessionPromise: Promise<TrackingSessionResponse>) => {
        return trackingSessionPromise.then((response: TrackingSessionResponse) => {
            const dto = new TrackingSession(response.data);

            trackingSessionId.value = dto.id;
            registrationNumber.value = dto.item.registrationNumber;

            if (trackingSessionId.value) {
                localStorage?.setItem(TRACKING_SESSION_ID_STORAGE_KEY, trackingSessionId.value)
            }
            localStorage?.setItem(REGISTRATION_NUMBER_STORAGE_KEY, registrationNumber.value)

            trackingSession.value = dto;

            return dto;
        }).catch((error: FetchError) => {
            if (404 !== error.statusCode) {
                throw error;
            }
        }).finally(() => {
            loading.value = false;
        });
    };

    const startTracking = async (registrationNumber: string) => {
        loading.value = true;

        const promise = apiFetch<TrackingSessionResponse>(
          '/tracking',
          {
              query: {
                  registration_number: registrationNumber
              },
              timeout: 5000,
          }
        );

        return handleTrackingSessionResponse(promise).then(trackingSession => {
            if (trackingSession?.id) {
                startRefreshing();
            }

            return trackingSession;
        });
    };

    const resumeTracking = async () => {
        return refreshQueueItem().then(trackingSession => {
            if (trackingSession && trackingSession.id && !trackingSession.item.isCalled) {
                startRefreshing();
            }

            return trackingSession;
        });
    };

    const stopTracking = () => {
        const trackingSessionIdValue = trackingSessionId.value;
        stopRefreshing();
        forgetTrackingSession();

        if (trackingSessionIdValue) {
            apiFetch(
              '/tracking/' + trackingSessionIdValue,
              {
                  method: "DELETE",
                  timeout: 5000,
              }
            ).catch(captureException)
        }
    };

    const refreshQueueItem = async () => {
        loading.value = true;

        const promise = apiFetch<TrackingSessionResponse>(
          '/tracking/' + trackingSessionId.value,
          {
              timeout: 5000,
          }
        );

        return handleTrackingSessionResponse(promise).then(trackingSession => {
            if (!trackingSession) {
                stopRefreshing();
                forgetTrackingSession();
            } else if (trackingSession.item.isCalled) {
                stopRefreshing();
            }

            return trackingSession;
        }).catch(captureException);
    };

    const forgetTrackingSession = () => {
        trackingSessionId.value = null;
        registrationNumber.value = null;
        trackingSession.value = null;

        localStorage?.removeItem(TRACKING_SESSION_ID_STORAGE_KEY);
        localStorage?.removeItem(REGISTRATION_NUMBER_STORAGE_KEY);
    };

    return {
        loading,
        registrationNumber,
        queueItem,
        hasTrackingSession,
        startTracking,
        resumeTracking,
        stopTracking,
        stopRefreshing,
        forgetTrackingSession,
    };
});
