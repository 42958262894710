import type TrackingSessionData from "~/api/response/data/Cars/Queue/TrackingSessionData";
import CarQueueItem from "~/dto/CarQueueItem";

export default class TrackingSession {
    id: string;
    item: CarQueueItem;

    constructor(data: TrackingSessionData) {
        this.id = data.id;
        this.item = new CarQueueItem(data.item);
    }
}